import { lazy } from "react";
import Loadable from "../components/Loadable";

const CustomerSettingsLayout: any = Loadable(
  lazy(() => import("../components/CustomerSettings/CustomerSettingsLayout"))
);

const customerSettingsRoutes = [
  { path: "/pages/settings", element: <CustomerSettingsLayout /> },
];

// TODO

export default customerSettingsRoutes;
