import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import useSettings from '../../contexts/hooks/useSettings';
import { ReactNode } from 'react';
import { Theme } from '@emotion/react';

type MainThemeProps = {
    children?: ReactNode;
}

const MainTheme = ({ children }: MainThemeProps) => {
    const settings = useSettings().settings;
    let activeTheme: Theme = settings.themes.find(x => x.key == settings.activeTheme)?.theme!;

    return (
        <ThemeProvider theme={activeTheme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};

export default MainTheme;