import { configureStore } from "@reduxjs/toolkit";
import { assetReducer } from "./slices/getAssets";
import { assetCategorySettingsReducer } from "./slices/getAssetCategories";
import { locationReducer as locationSettingsReducer } from "./slices/getLocationSettings";
import { assetLocationReducer } from "./slices/getAssetLocations";
import { customerReducer } from "./slices/getCustomers";
import { testgroupReducer } from "./slices/getTestGroups";
import { locationsReducer } from "./slices/getLocations";

const store = configureStore({
  reducer: {
    assets: assetReducer,
    assetCategories: assetCategorySettingsReducer,
    customers: customerReducer,
    locationSettings: locationSettingsReducer,
    assetLocations: assetLocationReducer,
    testGroups: testgroupReducer,
    locations: locationsReducer,
  },
  devTools: process.env.NODE_ENV !== "production", // TODO
});

export type AppDispatch = typeof store.dispatch;

export default store;
