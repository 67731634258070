import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ICustomer } from "../../types/Customer.type";
import axiosInstance from "../../utils/create-axios";
import { TestGroupData } from "../../types/TestGroup.type";

export type IinitialState = {
  error?: string;
  testGroups: TestGroupData[];
  loading: boolean;
};

const initialState: IinitialState = {
  error: undefined,
  testGroups: [],
  loading: false,
};

export const getTestGroups = createAsyncThunk("get_test_groups", async () => {
  const res = await axiosInstance.get("/api/test-groups/all");
  return res.data;
});

export const deleteTestGroup = createAsyncThunk(
  "deleteAsset",
  async (testGroupId: any) => {
    console.log(testGroupId);
    const res = await axiosInstance.delete(`/api/test-groups/delete`, {
      data: {
        id: testGroupId,
      },
    });
    return res.data;
  }
);

const testGroupSlice = createSlice({
  initialState,
  name: "testGroups",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTestGroups.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTestGroups.fulfilled, (state, actions: any) => {
        state.loading = false;
        state.testGroups = actions.payload;
      })
      .addCase(getTestGroups.rejected, (state, actions) => {
        state.loading = false;
        state.testGroups = [];
        state.error = actions.error ? actions.error.message : "";
      })
      .addCase(deleteTestGroup.fulfilled, (state, actions) => {
        state.loading = false;
        state.testGroups = state.testGroups.filter(
          (x) => !(x.id === actions.meta.arg)
        );
      });
  },
});

export const testgroupReducer = testGroupSlice.reducer;
