import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/create-axios";
import { IAssetCategory } from "../../types/CustomerSettings.types";

export type IinitialStateAssetCategories = {
  error?: string;
  assetCategories: IAssetCategory[];
  loading: boolean;
};

const initialState: IinitialStateAssetCategories = {
  error: undefined,
  assetCategories: [],
  loading: false,
};

export const getAssetCategories = createAsyncThunk(
  "get_asset_categories",
  async () => {
    const res = await axiosInstance.get(`/api/asset-categories/`);

    const results = res.data.map((x: IAssetCategory) => {
      return {
        ...x,
        is_testable:
          x.requires_continuity_test ||
          x.requires_dielectric_test ||
          x.requires_inspection,
      };
    });
    return results;
  }
);

export const addAssetCategory = createAsyncThunk(
  "addAssetCategories",
  async (assetCategory: IAssetCategory) => {
    console.log(assetCategory);
    const res = await axiosInstance.post("/api/asset-categories/new", {
      ...assetCategory,
      customerId: assetCategory.customer?.id,
    });
    return res.data;
  }
);

export const updateAssetCategory = createAsyncThunk(
  "updateAssetCategories",
  async (assetCategory: IAssetCategory) => {
    const res = await axiosInstance.put(
      "/api/asset-categories/update",
      assetCategory
    );
    return res.data;
  }
);

export const deleteAssetCategory = createAsyncThunk(
  "deleteAssetCategory",
  async (assetCategory: IAssetCategory) => {
    const res = await axiosInstance.delete("/api/asset-categories/delete", {
      data: assetCategory,
    });
    return res.data;
  }
);

// export const updateCustomerSettingsById = async (updatedSettings: ICustomerSettings) => {
//   console.log('update updatedSettings', updatedSettings);
//   const res = await axiosInstance.put('/api/settings/', updatedSettings);
//   console.log('add updatedSettings', res.data);
//   return res.data;
// }

const assetCategorySettingsSlice = createSlice({
  initialState,
  name: "assetCategorySettings",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAssetCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAssetCategories.rejected, (state, actions) => {
        state.loading = false;
        state.assetCategories = <IAssetCategory[]>[];
        state.error = actions.error ? actions.error.message : "";
      })
      .addCase(getAssetCategories.fulfilled, (state, actions) => {
        state.loading = false;
        state.assetCategories = actions.payload;
      })
      .addCase(updateAssetCategory.fulfilled, (state, actions) => {
        state.loading = false;
        state.assetCategories = state.assetCategories.map((al) => {
          if (al.id === actions.payload.id) {
            al = actions.payload;
          }
          return al;
        });
      })
      .addCase(addAssetCategory.fulfilled, (state, actions) => {
        state.loading = false;
        state.assetCategories.push(actions.payload);
      })
      .addCase(deleteAssetCategory.fulfilled, (state, actions) => {
        state.loading = false;
        state.assetCategories = state.assetCategories.filter(
          (x) => !(x.id === actions.meta.arg.id)
        );
      });
  },
});

export const assetCategorySettingsReducer = assetCategorySettingsSlice.reducer;
