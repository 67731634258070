import { Navigate, RouteObject } from "react-router-dom";
import MainLayout from "../components/Layouts/MainLayout";
import dashboardRoutes from "./DashboardRoutes";
import assetRoutes from "./AssetRoutes";
import customerSettingsRoutes from "./CustomerSettingsRoutes";
import AuthGuard from "../auth/AuthGuard";
import sessionRoutes from "./SessionRoutes";
import testingRoutes from "./TestingRoutes";

const routes: RouteObject[] = [
  {
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      ...assetRoutes,
      ...customerSettingsRoutes,
      ...testingRoutes,
    ],
  },
  ...sessionRoutes,
  { path: "/", element: <Navigate to="pages/asset-list" /> },
  // { path: '*', element: <NotFound /> }, // TODO
];

export default routes;
