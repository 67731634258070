// import useSettings from 'app/hooks/useSettings';
import { IMainLayoutSettings, MainLayoutSettings } from './Layout1/layoutSettings';
import AppSuspense from '../AppSuspense';
import { MainLayouts } from './index';

const MainLayout = (props: any) => {
    const settings: IMainLayoutSettings = MainLayoutSettings; // TODO

    const Layout = MainLayouts.find(l1 => l1.name == settings.activeLayout)?.component!;

    return (
        <AppSuspense>
            <Layout {...props} />
        </AppSuspense>
    );
};

export default MainLayout;