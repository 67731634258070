import { IAssetCategory } from "./CustomerSettings.types";
import { AssetLocation } from "./LocationSetting.type";
import { TestGroupData } from "./TestGroup.type";

// export enum AssetVariant {
//   RUBBER_GLOVE = "rubber_glove",
//   RUBBER_SLEEVE = "rubber_sleeve",
//   RUBBER_BLANKET = "rubber_blanket",
//   RUBBER_LINEHOSE = "rubber_linehose",
//   OTHER_COVERUP = "other_coverup",
//   PROTECTIVE_GROUND = "protective_ground",
//   MECHANICAL_JUMPER = "mechanical_jumper",
//   FIBERGLASS_HOT_STICK = "fiberglass_hot_stick"
// }

export enum AssetHand {
  LEFT = "left",
  RIGHT = "right",
  NONE = "none",
}

export enum AssetClass {
  CLASS_00 = "class_00",
  CLASS_0 = "class_0",
  CLASS_1 = "class_1",
  CLASS_2 = "class_2",
  CLASS_3 = "class_3",
  NONE = "none",
}

export enum AssetLocationType {
  FIELD = "field",
  LAB = "lab",
  WAREHOUSE = "warehouse",
}

export interface IAsset {
  id?: string;
  barcode_number?: string;
  read_number?: string;
  manufacturer?: string;
  category?: IAssetCategory;
  class?: AssetClass;
  location?: AssetLocation;
  size?: string;
  length?: string;
  notes?: string;
  compliance_status?: string;
  handedness?: string;
  last_test_date?: Date;
  last_test_result?: string;
  test_freq?: number;
  lastTestGroup?: TestGroupData;
}
