import { ITheme, themes } from "../../Themes/initThemes";

export interface BaseSettings {
    show?: boolean,
    fixed?: boolean,
    theme?: string,
    bgImgURL?: string,
    mode?: string,
}

export interface IMainLayoutSettings {
    activeLayout: string;
    activeTheme: string;
    perfectScrollbar: boolean;
    themes: ITheme[];
    layout1Settings?: {
        mode?: string;
        leftSidebar?: BaseSettings;
        navbar?: BaseSettings;
        topbar?: BaseSettings
    }
    layout2Settings?: {
        mode?: string;
        leftSidebar?: BaseSettings;
        navbar?: BaseSettings;
        topbar?: BaseSettings
    }
    footer: BaseSettings;
    secondarySidebar?: any;
}

export const MainLayoutSettings: IMainLayoutSettings = {

    activeLayout: "layout1", // layout1, layout2
    activeTheme: "blue",
    perfectScrollbar: false,
    themes: themes,
    layout1Settings: {
        leftSidebar: {
            show: true,
            mode: "full",
            theme: "slateDark1",
            // bgImgURL: "/assets/images/sidebar/sidebar-bg-dark.jpg",
        },
        topbar: {
            show: true,
            fixed: true,
            theme: "whiteBlue",
        },
    },
    layout2Settings: {
        mode: "contained", // boxed | contained | full
        topbar: {
            show: true,
            fixed: true,
            theme: "slateDark1"
        },
        navbar: {
            show: true,
            theme: "slateDark2"
        },
        leftSidebar: { // Only for mobile devices
            show: true,
            mode: "close", // full | close | compact | mobile
            theme: "slateDark1"
        }
    },
    footer: {
        show: true,
        fixed: false,
        theme: "slateDark1"
    }

};
