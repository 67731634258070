import { lazy } from 'react';
import Loadable from '../components/Loadable';

const Dashboard: any = Loadable(lazy(() => import('../components/Dashboard')));

const dashboardRoutes = [
    { path: '/dashboard/default', element: <Dashboard /> },
];

// TODO

export default dashboardRoutes;