import axios from "axios";
import { createContext, useEffect, useReducer } from "react";
import { getBaseUrl, requests } from "../../apis/Base.api";
import { UserProfileAPI } from "../../apis/UserProfile.api";

const initialState = {
  user: undefined,
  isInitialised: false,
  isAuthenticated: false,
};

const isValidToken = (accessToken: string) => {
  if (!accessToken) return false;

  // const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  // return decodedToken.exp > currentTime;
  return true;
};

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "INIT": {
      // console.log(action.payload);
      const { isAuthenticated, user } = action.payload;
      return { ...state, user, isAuthenticated, isInitialised: true };
    }
    case "LOGIN": {
      const { user } = action.payload;
      return { ...state, user, isAuthenticated: true };
    }
    case "LOGOUT": {
      return { ...state, isAuthenticated: false, user: null };
    }
    case "REGISTER": {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }
    default: {
      return state;
    }
  }
};

// interface AuthContextType {
//   user: null,
//   isInitialised: false,
//   isAuthenticated: false,
//   method: "JWT",
//   logout: () => {},
//   login: () => {},
//   register: () => {},
// };

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  logout: () => {},
  login: (email: any, password: any) => Promise.resolve(),
  register: () => Promise.resolve(),
});

export const AuthProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (email: any, password: any) => {
    const data = await axios
      .create({
        baseURL: getBaseUrl(),
        // timeout: 15000,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .post("/api/auth/login", {
        email,
        password,
      })
      .then(async (x) => {
        const accessToken = x.data.accessToken;
        const user = x.data.user;
        setSession(accessToken);
        await dispatch({ type: "LOGIN", payload: { user } });
      });
  };

  const register = async ({ email, username, password }: any) => {
    const { data } = await axios.post(
      "/api/auth/register",
      JSON.stringify({
        email,
        username,
        password,
      })
    );

    const { accessToken, user } = data;
    setSession(accessToken);
    dispatch({ type: "REGISTER", payload: { user } });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };
  // console.log("jwt", state);

  useEffect(() => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        let user;
        const response = UserProfileAPI.getUserProfile().then((res) => {
          // console.log("xx: ", res);
          user = res;
          // console.log(user);
          dispatch({
            type: "INIT",
            payload: { isAuthenticated: true, user },
          });
        });
      } else {
        dispatch({
          type: "INIT",
          payload: { isAuthenticated: false, user: null },
        });
      }
    } catch (err) {
      dispatch({
        type: "INIT",
        payload: { isAuthenticated: false, user: null },
      });
    }
  }, [dispatch]);

  // if (!state.isInitialised) {
  //   return <MatxLoading />;
  // }

  return (
    <AuthContext.Provider
      value={{ ...state, method: "JWT", login, logout, register }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
