import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ICustomer } from "../../types/Customer.type";
import axiosInstance from "../../utils/create-axios";
import { CustomerAPI } from "../../apis/Customer.api";

export type IinitialState = {
  error?: string;
  customers: ICustomer[];
  loading: boolean;
};

const initialState: IinitialState = {
  error: undefined,
  customers: [],
  loading: false,
};

export const getCustomers = createAsyncThunk("get_customers", async () => {
  const res = await axiosInstance.get("/api/customers/all");
  return res.data;
});

export const getCustomersWithLabPermission = createAsyncThunk(
  "get_customers",
  async (labId: string) => {
    const res = labId
      ? await axiosInstance.get(`/api/customers/lab-settings/${labId}`)
      : {
          data: [],
        };
    return res.data;
  }
);

const customerSlice = createSlice({
  initialState,
  name: "customers",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomers.fulfilled, (state, actions: any) => {
        state.loading = false;
        state.customers = actions.payload;
      })
      .addCase(getCustomers.rejected, (state, actions) => {
        state.loading = false;
        state.customers = [];
        state.error = actions.error ? actions.error.message : "";
      });
  },
});

export const customerReducer = customerSlice.reducer;
