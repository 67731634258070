import './App.css';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import routes from '../../routes/routes';
// import { store } from '../../redux/Store';
import MainTheme from '../Themes/DefaultTheme';
import store from '../../redux/Store';
import { SettingsProvider } from '../../contexts/SettingsContext';
import { AuthProvider } from '../../contexts/contexts/JWTAuthContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const App = () => {
  const content = useRoutes(routes);

  return (
    <Provider store={store}>
      <SettingsProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MainTheme>
            <AuthProvider>{content}</AuthProvider>
          </MainTheme>
        </LocalizationProvider>
      </SettingsProvider>
    </Provider>
  );
}

export default App;
