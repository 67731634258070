import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LocationSettingsAPI } from "../../apis/LocationSettings.api";
import { Location } from "../../types/Location.type";
import { LocationsAPI } from "../../apis/Location.api";

export type IinitialState = {
  error?: string;
  locations: Location[];
  loading: boolean;
};

const initialState: IinitialState = {
  error: undefined,
  locations: [],
  loading: false,
};

export const getLocations = createAsyncThunk("get_locations", async () => {
  const res = await LocationsAPI.getAll();
  return res;
});

const locationsSlice = createSlice({
  initialState,
  name: "locations",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLocations.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLocations.fulfilled, (state, actions) => {
        state.loading = false;
        state.locations = actions.payload;
      })
      .addCase(getLocations.rejected, (state, actions) => {
        state.loading = false;
        state.locations = [];
        state.error = actions.error ? actions.error.message : "";
      });
  },
});

export const locationsReducer = locationsSlice.reducer;
