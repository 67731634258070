import React, { createContext, useState } from 'react'

import { merge } from 'lodash'
import { IMainLayoutSettings, MainLayoutSettings } from '../components/Layouts/Layout1/layoutSettings'

type SettingsContextType = {
    settings: IMainLayoutSettings,
    //   saveTodo: (todo: ITodo) => void,
    updateSettings: (settings?: IMainLayoutSettings) => void,
};

const SettingsContext = React.createContext<SettingsContextType>({
    settings: MainLayoutSettings,
    updateSettings: () => { }
});

export const SettingsProvider = ({ settings, children }: any) => { // TODO
    const [currentSettings, setCurrentSettings] = useState(
        settings || MainLayoutSettings
    )

    const handleUpdateSettings = (update: any = {}) => { // TODO
        const merged = merge({}, currentSettings, update)
        setCurrentSettings(merged)
    }

    return (
        <SettingsContext.Provider value={{ settings: currentSettings, updateSettings: handleUpdateSettings }
        }>
            {children}
        </SettingsContext.Provider>
    );
}

export default SettingsContext;
