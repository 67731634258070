import axios, { AxiosInstance } from "axios";
import { getBaseUrl } from "../apis/Base.api";

// const accessToken = window.localStorage.getItem("accessToken");
// console.log('accessT: ', accessToken);

const axiosInstance: AxiosInstance = axios.create({
    baseURL: getBaseUrl(),
    headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('accessToken')
    }
});

// axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

export default axiosInstance;