import { Navigate, useLocation, useRoutes } from "react-router-dom";
import useAuth from "../contexts/hooks/useAuth";
import { useEffect } from "react";
import { IUser } from "../types/User.type";
import routes from "../routes/routes";
import { UserProfileAPI } from "../apis/UserProfile.api";

const userHasPermission = (pathname: string, user: IUser, routes: any[]) => {
  if (!user) {
    return false;
  }

  const matched = routes.find((r) => r.path === pathname);

  const authenticated =
    matched && matched.auth && matched.auth.length
      ? matched.auth.includes(user.role)
      : true;
  return authenticated;
};

const tokenValidation = () => {
  const accessToken = localStorage.getItem("accessToken");
  return accessToken && isValidToken(accessToken);
};

const isValidToken = (accessToken: string) => {
  if (!accessToken) return false;

  // const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  // return decodedToken.exp > currentTime;
  return true;
};

const AuthGuard = ({ children }: any) => {
  const { pathname } = useLocation();
  let isAuthenticated: any = useAuth().isAuthenticated;

  // console.log("hgfy: ", isAuthenticated);
  let user: IUser | undefined = useAuth().user;
  // console.log("gfj: ", user);
  const flatRoutes: any[] = [];
  const routes1 = routes.map((x) => {
    return x.children ? flatRoutes.push(...x.children) : flatRoutes.push(x);
  });

  if (tokenValidation() && !isAuthenticated) {
    isAuthenticated = tokenValidation();
    const response = UserProfileAPI.getUserProfile().then((res) => {
      // console.log("xx: ", res);
      user = res;
    });
  }

  const hasPermission = true;
  // user
  //   ? userHasPermission(pathname, user, flatRoutes)
  //   : false;
  let authenticated = isAuthenticated && hasPermission;
  // console.log("auth checek1: ", isAuthenticated);
  // console.log("auth checek2: ", hasPermission);
  // console.log("auth checek3: ", authenticated);

  // // IF YOU NEED ROLE BASED AUTHENTICATION,
  // // UNCOMMENT ABOVE LINES
  // // AND COMMENT OUT BELOW authenticated VARIABLE
  useEffect(() => {}, [isAuthenticated]);

  if (authenticated) return <>{children}</>;

  return <Navigate replace to="/session/signin" state={{ from: pathname }} />;
};

export default AuthGuard;
