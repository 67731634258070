import { lazy } from "react";
import Loadable from "../components/Loadable";
import ViewTestGroup from "../components/Testing/ViewTestGroup";

const NewTestGroupLayout: any = Loadable(
  lazy(() => import("../components/Testing/NewTestGroup"))
);
const OpenTestGroupLayout: any = Loadable(
  lazy(() => import("../components/Testing/OpenTestGroup"))
);
const CompleteTestingLayout: any = Loadable(
  lazy(() => import("../components/Testing/CompleteTestGroups/CompleteTesting"))
);
const TestReportsLayout: any = Loadable(
  lazy(() => import("../components/Testing/TestReports"))
);
const ViewTestGroupLayout: any = Loadable(
  lazy(() => import("../components/Testing/ViewTestGroup"))
);
const ViewTestReportsLayout: any = Loadable(
  lazy(() => import("../components/Testing/ViewTestReport"))
);

const testingRoutes = [
  { path: "/testing/new-group", element: <NewTestGroupLayout /> },
  { path: "/testing/test-group/:id", element: <ViewTestGroupLayout /> },
  { path: "/testing/open-test-groups", element: <OpenTestGroupLayout /> },
  { path: "/testing/complete-testing/:id", element: <CompleteTestingLayout /> },
  { path: "/testing/reports", element: <TestReportsLayout /> },
  { path: "/testing/reports/:id", element: <ViewTestReportsLayout /> },
];

// TODO

export default testingRoutes;
