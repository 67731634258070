import { createTheme } from '@mui/material';
import { forEach, merge } from 'lodash';
import themeOptions from './themeOptions';
import { themeColors } from './themeColors';
import { Theme } from '@emotion/react';

export interface ITheme {
    key: string;
    theme: Theme;
};

const createAppThemes = () => {
    let themes: ITheme[] = []; // TODO

    forEach(themeColors, (value: any, key: any) => {
        themes.push({key: key, theme: createTheme(merge({}, themeOptions, value))});
    });

    return themes;
}
export const themes = createAppThemes();