import axios, { AxiosResponse } from "axios";
// require('dotenv').config()

export const getBaseUrl = () => {
  let url;
  switch (process.env.REACT_APP_NODE_ENV) {
    case "production":
      url = process.env.REACT_APP_PRODURL;
      break;
    case "development":
      url = "http://localhost:8080";
      break;
    default:
      url = "https://google.com";
  }

  return process.env.REACT_APP_PRODURL;
};

const instance = axios.create({
  baseURL: getBaseUrl(),
  timeout: 15000,
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("accessToken"),
  },
});

const responseBody = (response: AxiosResponse) => response.data;

export const requests = {
  get: (url: string) => instance.get(url).then(responseBody),
  post: (url: string, body: {}) => instance.post(url, body).then(responseBody),
  put: (url: string, body: {}) => instance.put(url, body).then(responseBody),
  delete: (url: string) => instance.delete(url).then(responseBody),
};
