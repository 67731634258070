import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AssetLocation } from "../../types/LocationSetting.type";
import { AssetLocationAPI } from "../../apis/AssetLocation.api";
import axiosInstance from "../../utils/create-axios";

export type IinitialState = {
  error?: string;
  assetLocations: AssetLocation[];
  loading: boolean;
};

const initialState: IinitialState = {
  error: undefined,
  assetLocations: [],
  loading: false,
};

export const getAssetLocations = createAsyncThunk(
  "get_asset_locations",
  async () => {
    const res = await axiosInstance.get("/api/asset-location/all");
    return res.data;
  }
);

export const updateAssetLocation = createAsyncThunk(
  "update_asset_locations",
  async (assetLocation: any) => {
    console.log(assetLocation);
    const res = await axiosInstance.put(
      "/api/asset-location/update",
      assetLocation
    );
    return res.data;
  }
);

const assetLocationSlice = createSlice({
  initialState,
  name: "assetLocations",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAssetLocations.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAssetLocations.fulfilled, (state, actions) => {
        state.loading = false;
        state.assetLocations = actions.payload;
      })
      .addCase(getAssetLocations.rejected, (state, actions) => {
        state.loading = false;
        state.assetLocations = [];
        state.error = actions.error ? actions.error.message : "";
      })
      .addCase(updateAssetLocation.fulfilled, (state, actions) => {
        state.loading = false;
        state.assetLocations = state.assetLocations.map((al) => {
          if (al.id === actions.payload.id) {
            al = actions.payload;
          }
          return al;
        });
      });
  },
});

export const assetLocationReducer = assetLocationSlice.reducer;
