import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LocationSettingsAPI } from "../../apis/LocationSettings.api";
import { LocationSetting } from "../../types/LocationSetting.type";

export type IinitialState = {
  error?: string;
  locationSettings: LocationSetting[];
  loading: boolean
}

const initialState: IinitialState = {
  error: undefined,
  locationSettings: [],
  loading: false,
};

export const getLocationSettings = createAsyncThunk("get_location_settings", async () => {
  const res = await LocationSettingsAPI.getAll();
  return res;
});

const locationSettingSlice = createSlice({
  initialState,
  name: "locationSettings",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLocationSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLocationSettings.fulfilled, (state, actions) => {
        state.loading = false;
        state.locationSettings = actions.payload;
      })
      .addCase(getLocationSettings.rejected, (state, actions) => {
        state.loading = false;
        state.locationSettings = [];
        state.error = actions.error ? actions.error.message : '';
      });
  },
});

export const locationReducer = locationSettingSlice.reducer;

