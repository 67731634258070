import React, { Suspense } from 'react';
import { JSX } from 'react/jsx-runtime';
import LoadingProgress from './LoadingProgress';

const Loadable = (Component: any) => (props: any) => {
    return (
        <Suspense fallback={<LoadingProgress />}>
            <Component {...props} />
        </Suspense>
    );
};

export default Loadable;