import { lazy } from "react";
import Loadable from "../components/Loadable";

const AssetList = Loadable(lazy(() => import("../components/AssetsComponent/AssetCatalog")));
const ViewAsset = Loadable(lazy(() => import("../components/AssetsComponent/assetView/AssetView")));
const AssetForm = Loadable(lazy(() => import("../components/AssetsComponent/AssetOnboardForm")));
const UploadFile = Loadable(lazy(() => import("../components/AssetsComponent/AssetOnboardFileUpload")));
const AssetAssignment = Loadable(lazy(() => import("../components/AssetsComponent/AssetAssignment")));
const AlertSettings = Loadable(lazy(() => import("../components/CustomerSettings/Alerts/AlertSettings")));

const assetRoutes = [
    { path: "/pages/asset-list", element: <AssetList /> },
    { path: "/assets/add-asset", element: <AssetForm /> },
    { path: "/assets/view-asset/:id", element: <ViewAsset /> },
    { path: "/pages/asset-assignment", element: <AssetAssignment /> },
    { path: "/assets/upload", element: <UploadFile /> },
    { path: "/assets/alerts", element: <AlertSettings /> },
];

export default assetRoutes;
