import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/create-axios";
import { AssetLocationType, IAsset } from "../../types/Asset.types";
import { AssetLocationAPI } from "../../apis/AssetLocation.api";
import { AssetLocation } from "../../types/LocationSetting.type";

export type IinitialState = {
  error?: string;
  assets: IAsset[];
  loading: boolean;
};

const initialState: IinitialState = {
  error: undefined,
  assets: [],
  loading: false,
};

export const getAssets = createAsyncThunk("get_assets", async () => {
  const res = await axiosInstance.get("/api/assets");
  // console.log('getAssets: ', res.data);
  const results = await res.data.map((x: IAsset) => {
    return {
      ...x,
      category: {
        ...x.category,
        is_testable:
          x.category?.requires_inspection ||
          x.category?.requires_continuity_test ||
          x.category?.requires_dielectric_test,
      },
    };
  });
  return results;
});

export const addAsset = async (asset: IAsset) => {
  // console.log('create asset', asset);
  const res = await axiosInstance.post("/api/assets/new", asset);
  const asset_loc: AssetLocation = {
    location: {
      // type: asset.location?.location.type!,
      id: asset.location?.location?.id!,
      type: asset.location?.location?.type || AssetLocationType.LAB,
      name: asset.location?.location?.name || "",
    },
    assetId: res.data.id!,
  };
  await AssetLocationAPI.create(asset_loc);
  return res.data;
};

// export const deleteAsset = async (asset: IAsset) => {
//     // console.log('delete asset', assetId);
//     const res = await axiosInstance.delete("/api/assets/delete", { data: asset });
//     getUsers();
//     console.log('delete asset', res.data);
//     return res.data;
// }

export const deleteAsset = createAsyncThunk(
  "deleteAsset",
  async (asset: IAsset) => {
    const res = await axiosInstance.delete("/api/assets/delete", {
      data: asset,
    });
    return res.data;
  }
);

export const updateAsset = async (asset: IAsset) => {
  const res = await axiosInstance.put("/api/assets/update", asset);
  return res.data;
};

const assetSlice = createSlice({
  initialState,
  name: "assets",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAssets.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAssets.fulfilled, (state, actions) => {
        state.loading = false;
        state.assets = actions.payload;
      })
      .addCase(getAssets.rejected, (state, actions) => {
        state.loading = false;
        state.assets = [];
        state.error = actions.error ? actions.error.message : "";
      })
      .addCase(deleteAsset.fulfilled, (state, actions) => {
        state.loading = false;
        state.assets = state.assets.filter((x) => x.id === actions.meta.arg.id);
      });
  },
});

export const assetReducer = assetSlice.reducer;
