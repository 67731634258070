import { Location } from "../types/Location.type";
import { requests } from "./Base.api";

export const LocationsAPI = {
  getAll: (): Promise<Location[]> => requests.get("/api/locations/"),
  // update: (update_asset_loc: LocationSetting): Promise<LocationSetting> =>
  //   requests.put("/api/location-settings/update", update_asset_loc),
  // create: (new_asset_loc: LocationSetting): Promise<LocationSetting> =>
  //   requests.post("/api/location-settings/new", new_asset_loc),
};
