import { LocationSetting } from "../types/LocationSetting.type";
import { requests } from "./Base.api";

export const LocationSettingsAPI = {
  getAll: (): Promise<LocationSetting[]> =>
    requests.get("/api/location-settings/"),
  update: (update_asset_loc: LocationSetting): Promise<LocationSetting> =>
    requests.put("/api/location-settings/update", update_asset_loc),
  create: (new_asset_loc: LocationSetting): Promise<LocationSetting> =>
    requests.post("/api/location-settings/new", new_asset_loc),
};
